<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Add Data Source</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.data_type.val"
                                        :description="form.data_type.inv"
                                        label="Data Type"
                                        :options="[
                                            {value: '1', label:'API JSON - tgju.org'},
                                            {value: '2', label:'tsetmc.com'},
                                            {value: '3', label:'netdania.com'},
                                            {value: '4', label:'HTML Processing'},
                                            {value: '5', label:'Data Mining among Specific Expressions'},
                                            {value: '6', label: 'Data Mining from Telegram Channel'},
                                            {value: '7', label:'Data Mining among Specific Expressions (Advanced)'},
                                            {value: '8', label:'Tadbir API (TMCTSE)'},
                                            {value: '9', label:'Tradingview'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.data_type.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.log_data.val"
                                        :description="form.log_data.inv"
                                        label="Data Logs"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.log_data.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <CTextarea v-model="form.detail.val" :description="form.detail.inv" label="Description" rows="1" />
                                </CCol>
                                <CCol sm="3" v-show="show == 1">
                                    <CInput v-model="form.source_url.val" :description="form.source_url.inv" label="Source URL" />
                                </CCol>
                                <CCol sm="3" v-show="show == 1">
                                    <CInput v-model="form.check_each_n_second.val" :description="form.check_each_n_second.inv" label="Check Each N Second" />
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary"> Submit</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                source_url: {val: '', inv: '', v: { req: true, max: 2048 }},
                data_type: {val: '', inv: '', v: { req: true, max: 1024 }},
                is_active: {val: '', inv: '', v: { req: true, max: 1024 }},
                log_data: {val: '', inv: '', v: { req: true, max: 1024 }},
                check_each_n_second: {val: '', inv: '', v: { req: true, max: 1024 }},
                detail: {val: '', inv: '', v: { req: true, min: 2, max: 2048 }},
			},
            show: 1,
        }
    },
    watch: {
        'form.data_type.val':{
            handler(){
                if(this.form.data_type.val == 6 || this.form.data_type.val == 8 || this.form.data_type.val == 9){
                    this.show = 0;
                } else {
                    this.show = 1;
                }
            }        
        }
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                if(this.form.data_type.val == 6 || this.form.data_type.val == 8 || this.form.data_type.val == 9){
                    if(key == 'source_url' || key == 'check_each_n_second'){
                        this.form[key].v.req = false;
                    }
                }
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if(this.form[key].v.req == true){
                                if (this.form[key].val == '') {
                                    this.form[key].inv = 'This field is required.';
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                for (const key in this.form){
                    data.set(key, this.form[key].val);
                }
                this.axios.post('arbitrage/data_sources/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Submitted Successfully.'
                        });
                        this.$router.push('/data/sources');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: "Error! please try again. Notice : you can't insert duplicate data for Telegram , Tadbir and Tradingview datasource !"
                        });
                    })
            }
        }
    }
}
</script>
